// Base colors
$blue-darker: #003DB0 !default;
$blue-dark: #2361DB !default;
$blue: #157EFB !default;
$blue-light: #00B4FC !default;
$blue-lighter: #78D2FE !default;
$orange: #FF542C !default;
$gray-darker: #353535 !default;
$gray-dark: #505050 !default;
$gray: #878787 !default;
$gray-light: #C8C8C8 !default;
$gray-light-alt: #DDDDDD !default;
$gray-lighter: #F4F4F4 !default;
$gray-lighter-alt: #F1F1F1 !default;
$green-light: #8AE234 !default;
$white: #FFFFFF !default;
$black: #000000 !default;

// Colorsenum.js
$ank-ui-color-link: $blue-lighter !default;
$ank-ui-color-btn-action: $blue !default;
$ank-ui-color-btn-creation: $blue-dark !default;
$ank-ui-color-selected: $blue-light !default;
$ank-ui-color-selected-bg: $blue !default;
$ank-ui-color-error: $orange !default;
$ank-ui-color-text: $gray-darker !default;
$ank-ui-color-text-colored: $white !default;
$ank-ui-color-picto: $gray !default;
$ank-ui-color-picto-colored: $white !default;
$ank-ui-color-hovered: $gray-lighter !default;
$ank-ui-color-font-document: $gray-dark !default;
$ank-ui-color-font-placeholder: $gray-light !default;

$ank-ui-color-client: $green-light !default;

$ank-ui-color-label: #505050 !default;
$ank-ui-color-btn-click: $blue-darker !default;

$ank-ui-color-body-background: $gray-lighter-alt !default;
$ank-ui-color-frame-background: $white !default;

$ank-ui-color-font-button: $ank-ui-color-text-colored !default;
$ank-ui-color-font-title: $ank-ui-color-text !default;
$ank-ui-color-btn-tool-bg: #E4E4E4 !default;

$ank-ui-font-size: 12px !default;
$ank-ui-line-height: 1.2 !default;

$ank-ui-font: 'Roboto-Regular', 'Helvetica', sans-serif !default;
$ank-ui-font-base-size: 1rem !default;
$ank-ui-title-size: 1rem !default;
$ank-ui-title-font-weight: bold !default;
$ank-ui-title-font-line-height: 1.333rem !default;
$ank-ui-title-font-variant: 'Roboto-Bold' !default;
$ank-ui-label-font-size: 1rem !default;
$ank-ui-label-font-weight: normal !default;
$ank-ui-label-font-variant: 'Roboto-Regular' !default;
$ank-ui-label-font-line-height: 1.333rem !default;
$ank-ui-value-font-size: 1rem !default;
$ank-ui-value-font-weight: normal !default;
$ank-ui-value-font-variant: 'Roboto-Bold' !default;
$ank-ui-value-font-line-height: 1.333rem !default;
$ank-ui-button-font-size: 1rem !default;
$ank-ui-button-font-weight: normal !default;
$ank-ui-button-font-variant: 'Roboto-Bold' !default;
$ank-ui-button-font-line-height: 1.333rem !default;

// Button 1
$ank-ui-button1-width: auto !default;
$ank-ui-button1-height: 2.5rem !default;
$ank-ui-button1-color-background: $ank-ui-color-btn-action !default;
$ank-ui-button1-color-text: $ank-ui-color-text-colored !default;
$ank-ui-button1-border-radius: 0.166rem !default;
$ank-ui-button1-padding: 2.5rem !default;
$ank-ui-button1-disabled-color-background: $ank-ui-color-font-placeholder !default;
$ank-ui-button1-disabled-color-text: $ank-ui-color-font-document !default;
$ank-ui-button1-disabled-hover-color-background: $ank-ui-button1-disabled-color-background !default;
$ank-ui-button1-disabled-hover-color-text: $ank-ui-button1-disabled-color-text;
$ank-ui-button1-disabled-opacity: 0.5 !default;
$ank-ui-button1-hover-color-background: $ank-ui-color-btn-click !default;
$ank-ui-button1-hover-color-text: $ank-ui-color-text-colored !default;
$ank-ui-button1-hover-shadow: 0.416rem 0.416rem 1rem 1rem #1508A5 inset !default;

// Button 2
$ank-ui-button2-color-text: #157EFB !default;
$ank-ui-button2-color-background: #FFFFFF !default;
$ank-ui-button2-color-border: #157EFB !default;

$ank-ui-button2-color-hover-text: #FFFFFF !default;
$ank-ui-button2-color-hover-background: #157EFB !default;

$ank-ui-button2-width: auto !default;
$ank-ui-button2-height: 2.5rem !default;
$ank-ui-button2-border-radius: 0.166rem !default;
$ank-ui-button2-padding: 2.5rem !default;

$ank-ui-button2-disabled-color-background: #FFFFFF !default;
$ank-ui-button2-disabled-color-text: #C8C8C8 !default;
$ank-ui-button2-disabled-hover-color-background: $ank-ui-button2-disabled-color-background !default;
$ank-ui-button2-disabled-hover-color-text: $ank-ui-button2-disabled-color-text !default;
$ank-ui-button2-disabled-color-border: #C8C8C8 !default;

// Button 3
$ank-ui-button3-width: auto !default;
$ank-ui-button3-border-radius: O !default;
$ank-ui-button3-padding: 2.833rem !default;
$ank-ui-button3-color-border: #157EFB !default;
$ank-ui-button3-disabled-color-background: #FFFFFF !default;
$ank-ui-button3-disabled-color-text: #C8C8C8 !default;
$ank-ui-button3-disabled-hover-color-background: #FFFFFF !default;
$ank-ui-button3-disabled-hover-color-text: #C8C8C8 !default;
$ank-ui-button3-color-text: #157EFB !default;
$ank-ui-button3-color-background: #FFFFFF !default;
$ank-ui-button3-color-hover-text: #157EFB !default;
$ank-ui-button3-color-hover-background: #FFFFFF !default;

// Toggle button
$ank-ui-toggle-color-bg-off: #EEE !default;
$ank-ui-toggle-color-text-off: #777 !default;
$ank-ui-toggle-padding-off: 0 0 0 2.5rem !default;
$ank-ui-toggle-transition: background-color 1s, padding-left 1s !default;
$ank-ui-toggle-color-bg-on: #00B4FC !default;
$ank-ui-toggle-color-text-on: $white !default;
$ank-ui-toggle-padding-on: 0 2rem 0 1rem !default;
$ank-ui-toggle-line-height: 1.72 !default;

// Radio button
$ank-ui-radio-color-bg: $white !default;
$ank-ui-radio-color-checked: $ank-ui-toggle-color-bg-on !default;
$ank-ui-radio-size: 1.33333rem !default;
$ank-ui-radio-size-checked: 0.83333rem !default;
$ank-ui-radio-border-selected: $gray !default;
$ank-ui-radio-border-unselected: fade-out($gray, 0.3) !default;
$ank-ui-check-font: 'Material Icons' !default;
$ank-ui-check-content: "\E5CA" !default;

// Attribute
$ank-ui-doc-edit-label-padding: 0 !default;
$ank-ui-doc-edit-attribute-bg: $white !default;
$ank-ui-doc-edit-attribute-height: 2.166rem !default;
$ank-ui-doc-edit-attribute-color-border: #E6E6E6 !default;
$ank-ui-doc-edit-attribute-border-radius: 0.33rem !default;
$ank-ui-doc-edit-attribute-border: solid $ank-ui-doc-edit-attribute-color-border 0.083rem !default;
$ank-ui-doc-edit-attribute-hover-color: #157EFB !default;
$ank-ui-doc-edit-attribute-focus-color: #157EFB !default;
$ank-ui-doc-edit-attribute-hover-border: solid $ank-ui-doc-edit-attribute-hover-color 0.083rem !default;
$ank-ui-doc-edit-attribute-hover-shadow: 0rem 0rem 0.583rem $ank-ui-doc-edit-attribute-hover-color !default;
$ank-ui-doc-edit-attribute-focus-shadow: 0rem 0rem 0.583rem $ank-ui-doc-edit-attribute-focus-color !default;
$ank-ui-doc-edit-attribute-error-color: $ank-ui-color-error !default;
$ank-ui-doc-edit-attribute-error-spacing: 0.166rem !default;
$ank-ui-doc-edit-attribute-error-text-color: $white !default;
$ank-ui-doc-read-label-padding: 0 !default;
$ank-ui-doc-read-attribute-height: auto !default;
$ank-ui-doc-read-attribute-border: 0.083rem solid #F1EFEF !default;
$ank-ui-doc-read-attribute-value-padding: 0 0 0.6666rem 0 !default;
$ank-ui-doc-read-attribute-link-color: #78D2FE !default;
$ank-ui-doc-read-attribute-picto-space: 0.6666rem !default;
$ank-ui-input-height: 2.5rem !default;

//Frames
$ank-ui-frame-font-variant: $ank-ui-value-font-variant !default;
$ank-ui-frame-padding: 0rem 1.166rem 0 1.666rem !default;
$ank-ui-frame-shadow:rgba(0, 0, 0, 0.1) 0 0.25rem 0.25rem !default;
$ank-ui-frame-color-title: #878787 !default;
$ank-ui-frame-size-title: 1.1rem !default;
$ank-ui-frame-header-border-bottom: 0.083rem solid #d8d9da !default;
$ank-ui-frame-header-padding: 0 0 0 0rem !default;
$ank-ui-frame-header-line-height: 2.5rem !default;
$ank-ui-frame-header-margin: 0 0 1.25rem 0 !default;

// Array
$ank-ui-array-shadow: $ank-ui-frame-shadow !default;
$ank-ui-array-header-background: white !default;
$ank-ui-array-padding: 0 1.1666rem 1.66666rem 1.1666rem !default;
$ank-ui-array-border: 1px solid #e8e8e8 !default;
$ank-ui-array-margin: 0 0 0.8333rem !default;
$ank-ui-array-border-radius: 0.25rem !default;

// Tabs
$ank-ui-tabs-height: 3rem !default;
$ank-ui-tab-active-bg: #FAFAFA !default;
$ank-ui-tab-inactive-bg: #E6E6E6 !default;
$ank-ui-tab-space: 0.166rem !default;
$ank-ui-tab-padding: 0.833rem !default;

@mixin A4ClientTabDecoration() {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 1.25rem 1.25rem 0;
  border-color: transparent $ank-ui-color-client transparent transparent;
}

// Menu
$ank-ui-menu-bg: #FAFAFA !default;
$ank-ui-menu-fixed-bg: #157EFB !default;
$ank-ui-menu-fixed-font: $ank-ui-font !default;
$ank-ui-menu-font-color: $gray !default;
$ank-ui-menu-fixed-font-color: #FFFFFF !default;
$ank-ui-menu-separator: 1px solid #ccc !default;
$ank-ui-menu-height: 2.833rem !default;
$ank-ui-menu-item-padding: 0 1.666rem 0 1.666rem !default;

// Icons
$ank-ui-icon-size: 14px !default;
$ank-ui-icon-color: $gray !default;
$ank-ui-icon-spacer: 0.583rem !default;

// Header
$ank-ui-header-height: 2.5rem !default;
$ank-ui-header-bg: $ank-ui-color-frame-background !default;
$ank-ui-header-title-margin: 0 !default;
$ank-ui-header-title-size: 1.2rem !default;

$dcp-set-padding: 10px !default;
$dcp-attribute-padding-base-vertical: 6px + 1 !default;

// Attribute decription field
$dcpAttributeDescriptionArrowSize: 8px !default;
$dcpAttributeDescriptionBackgroundColor: #f5f5f5 !default;
