.smart-element-grid {
  .k-grid-header {
    background: $ank-grid-header-background;
    &:hover .k-header:not(:first-child) {
      border-left-width: 1px;
      border-left-color: rgba(33, 37, 41, 0.125);
    }
  }

  &.smart-element-grid--selectable .k-master-row {
    &.k-state-selected {
      box-shadow: inset 3px 0px 0px 0px $ank-ui-color-client;
      &,
      td,
      th {
        background-color: transparentize($ank-ui-color-link, 0.85);
        color: $ank-ui-color-text;
        font-weight: bold;
      }
    }
    &:not(.k-state-selected) {
      &:hover {
        background-color: $ank-ui-color-hovered;
      }
    }
  }
}
.smart-element-grid-window {
  .columns-management-table {

    .slider:before {

      background-color: red;
    }

    input:checked + .slider {
      background-color: $ank-ui-color-selected;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $ank-ui-color-selected;
    }
  }
}
