// Import of the other css : to remove because it will be provided by the server
.identity-component {
  .identity {

    .identity-badge {
      background-color: $ank-ui-color-client;
      border-radius: 50%;
    }

    &.interactive .identity-badge:hover {
      background-color: lighten($ank-ui-color-client, 20%);
      cursor: pointer;
    }

    .identity-badge-initials {
      color: $ank-identity-badge-font-color;
    }

    .identity-badge-icon {
      color: $ank-identity-badge-font-color;
    }
  }

  .identity-modification-popup {
    background: $white;

    ul {
      .action:hover {
        background-color: $ank-ui-color-hovered;
      }
    }
  }
}